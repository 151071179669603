.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.uploader-container {
  background-color: #0b5a7e;
  min-height: 100px;
  color: white;
  max-width: 100% !important;
  padding-top:24px;
}

.uploader-container a, .uploader-container a:active, .uploader-container a:hover {
  color: white;
}

.custom-dropzone {
  background-color: #fafafa;
  border: 2px dashed #cccccc !important;
}

.custom-dropzone .dzu-inputLabel {
  color: #cccccc;
  
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


#root, .App, html, body, #map {
  height: 100%;
  margin: 0px;
}