#Navbar {
    height: 10%;
}

#google-map {
    height: 90%;
    width: 100%;
    position: absolute;
    top: 10%;
    left: 0;
}

.navbar-title {
    
}

.AlertBox {
    position: absolute;
    z-index: 1;
    bottom: 0%;
    width: 90%;
    height: 8%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.info-window-container {
    min-width: 240px;
    text-align: left;
    padding-left: 10px !important;

}

.info-window-id-container {
    padding-bottom: 5px;
    padding-top: 5px;
    text-align: left;
        /* border-bottom: 1px solid #f1f2f2; */

}

.info-window-title-container {
    padding-top: 5px;
    /* border-bottom: 1px solid #f1f2f2; */
    text-align: left;
    
}

.info-window-content-container {
    padding-top: 2px;
    padding-bottom: 10px;
    /* border-bottom: 1px solid #f1f2f2; */
    text-align: left;
    
}

.info-window-rate {
    font-size: 16px;
    color: #005A7F;
    font-weight: 600;
}

.info-window-value {
    font-size: 22px;
    color: #1E3050;
    font-weight: 600;
}

.info-window-unit {
    font-size: 15px;
    color: black;
    font-weight: 400;
}

.info-window-meter-id {
    font-size: 20px;
    color: #414656;
    font-weight: 700;
}

.meter-rate-time-range {
    font-size: 14px;
    font-weight: 300;
    color: #414656;
}